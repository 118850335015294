<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Nombre*</label>
      <input
        type="text"
        class="form-control"
        required
        v-model="reg.name"
        minlength="4"
      />
    </div>
    <div class="form-group">
      <label for>Razon Social</label>
      <input
        type="text"
        class="form-control"
        required
        v-model="reg.business_name"
        minlength="4"
      />
    </div>
    <div class="form-group">
      <label for>Dirección*</label>
      <input
        type="text"
        class="form-control"
        required
        v-model="reg.address"
        minlength="4"
      />
    </div>
    <div class="form-group">
      <label for="">Color</label>
      <app-select-mate-color v-model="reg.color"></app-select-mate-color>
    </div>
    <div class="form-group">
      <label for>RUC</label>
      <input
        type="text"
        class="form-control"
        required
        v-model="reg.ruc"
        minlength="8"
      />
    </div>
    <div class="form-group">
      <label for>Logo/ Imagen representativa</label>
      <input type="file" ref="inImg" accept="image/*" />
    </div>
    <div class="form-group">
      <app-switch v-model="reg.enable">Activo</app-switch>
    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-primary">Guardar</button>
    </div>
  </form>
</template>

<script>
import { AdminService } from "../AdminService";
import { GeneralService } from "../../general-module/GeneralService";

export default {
  data() {
    return {
      reg: {}
    };
  },
  methods: {
    loadReg(item) {
      this.reg = JSON.parse(JSON.stringify(item));
    },
    save() {
      var reg = JSON.parse(JSON.stringify(this.reg));
      new Promise(rsv => {
        if (this.$refs.inImg.files && this.$refs.inImg.files.length > 0) {
          GeneralService.uploadImages(this.$refs.inImg.files).then(res => {
            reg.file_id = res[0];
            rsv();
          });
        } else {
          rsv();
        }
      }).then(() => {
        AdminService.saveEntity(reg).then(() => {
          this.$emit("submit");
        });
      });
    },
    reset() {
      this.reg = {};
    }
  }
};
</script>

<style>
</style>
