<template>
  <div class="row">
    <div class="col">
      <h4>ENTIDADES</h4>
      <div class="card table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>NOMBRE</th>
              <th>DIRECCIÓN</th>
              <th>ESTADO</th>
              <th>LOGO</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.address }}</td>
              <td>
                <app-badge-status :value="item.enable"></app-badge-status>
              </td>
              <td>
                <app-img-viewer
                  v-if="item.file_id"
                  :fileId="item.file_id"
                  style="width: 250px"
                ></app-img-viewer>
              </td>
              <td class="text-right">
                <div class="btn-group">
                  <button
                    class="btn btn-sm btn-light"
                    @click="
                      $refs.dialogForm.show();
                      $refs.formItem.loadReg(item);
                    "
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <button
                  class="btn btn-primary"
                  @click="
                    $refs.dialogForm.show();
                    $refs.formItem.reset();
                  "
                >
                  Nuevo
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <app-modal-basic ref="dialogForm">
      <FormEntity
        ref="formItem"
        @submit="
          $store.dispatch('admin/getEntitys', true);
          $refs.dialogForm.hide();
          getItems();
        "
      ></FormEntity>
    </app-modal-basic>
  </div>
</template>

<script>
import FormEntity from "./Form.vue";
import { AdminService } from "../AdminService";

export default {
  components: {
    FormEntity
  },
  data() {
    return {
      items: []
    };
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      AdminService.getEntitys({}, true).then(list => (this.items = list));
    }
  }
};
</script>

<style></style>
